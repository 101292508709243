body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}



.accordion {
  --bs-accordion-color: var(--bs-body-color);
  --bs-accordion-bg: var(--bs-body-bg);
  --bs-accordion-transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out, border-radius 0.15s ease;
  --bs-accordion-border-color: var(--bs-border-color);
  --bs-accordion-border-width: var(--bs-border-width);
  --bs-accordion-border-radius: var(--bs-border-radius);
  --bs-accordion-inner-border-radius: calc(var(--bs-border-radius) - var(--bs-border-width));
  --bs-accordion-btn-padding-x: 1.25rem;
  --bs-accordion-btn-padding-y: 1rem;
  --bs-accordion-btn-color: var(--bs-body-color);
  --bs-accordion-btn-bg: var(--bs-accordion-bg);
  --bs-accordion-btn-icon: url(data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23212522'%3E%3Cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3E%3C/svg%3E);
  --bs-accordion-btn-icon-width: 1.25rem;
  --bs-accordion-btn-icon-transform: rotate(-180deg);
  --bs-accordion-btn-icon-transition: transform 0.2s ease-in-out;
  --bs-accordion-btn-active-icon: url(data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%236ea8fe'%3E%3Cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3E%3C/svg%3E);
  --bs-accordion-btn-focus-border-color: #86b7fe;
  --bs-accordion-btn-focus-box-shadow: 0 0 0 0.25rem #0d6efd40;
  --bs-accordion-body-padding-x: 1.25rem;
  --bs-accordion-body-padding-y: 1rem;
  --bs-accordion-active-color: var(--bs-primary-text-emphasis);
  --bs-accordion-active-bg: var(--bs-primary-bg-subtle);
}
.accordion-item:first-of-type {
  border-top-left-radius: 0.375rem;
  border-top-right-radius: 0.375rem;
}
.accordion-header {
  margin-bottom: 0;
}

.accordion-item:first-of-type .accordion-button {
  border-top-left-radius: 0.375rem;
  border-top-right-radius: 0.375rem;
  font-weight: 600;
}
.accordion-item:last-of-type {
  border-bottom-left-radius: 0.375rem;
  border-bottom-right-radius: 0.375rem;
}
.accordion-item {
  background-color: var(--bs-accordion-bg);
  border: 1px solid #dfe0e1;
  color: #000;
}
.accordion-button:not(.collapsed):after{
  background-image: var(--bs-accordion-btn-active-icon);
    transform: var(--bs-accordion-btn-icon-transform);
}
.accordion-button {
  font-weight: 600;
  align-items: center;
  background-color: var(--bs-accordion-btn-bg);
  border: 0;
  border-radius: 0;
  color: var(--bs-accordion-btn-color);
  display: flex;
  font-size: 1rem;
  overflow-anchor: none;
  padding: var(--bs-accordion-btn-padding-y) var(--bs-accordion-btn-padding-x);
  position: relative;
  text-align: left;
  transition: var(--bs-accordion-transition);
  width: 100%;
}
[data-bs-theme=dark] .accordion-button:after {
  --bs-accordion-btn-icon: url(data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%236ea8fe'%3E%3Cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3E%3C/svg%3E);
  --bs-accordion-btn-active-icon: url(data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%236ea8fe'%3E%3Cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3E%3C/svg%3E);
}
.accordion-button:after {
  background-image: var(--bs-accordion-btn-icon);
  background-repeat: no-repeat;
  background-size: var(--bs-accordion-btn-icon-width);
  content: "";
  flex-shrink: 0;
  height: var(--bs-accordion-btn-icon-width);
  margin-left: auto;
  transition: var(--bs-accordion-btn-icon-transition);
  width: var(--bs-accordion-btn-icon-width);
}
.collapse:not(.show) {
  display: none;
}
.accordion-body {
  padding: var(--bs-accordion-body-padding-y) var(--bs-accordion-body-padding-x);
}
.accordion-button:not(.collapsed) {
  background-color: #c6252a;
 
  border-bottom: 1px solid #86b7fe;
  color: #fff;
}
body:not(.navigation-with-keyboard) :not(input):focus {
  outline: none;
}
.accordion-button:focus {
  border-color: var(--bs-accordion-btn-focus-border-color);
  box-shadow: var(--bs-accordion-btn-focus-box-shadow);
  outline: 0;
  z-index: 3;
}
.gray-light-bg-new{
  background-color: #ebebeb;
}

.prt_floting_customsett {
  position: fixed;
  top: 28%;
left: 0;
  padding: 3px 0 3px;
  margin: 0px 0 0;
  background-color: #fff;
  box-shadow: 0 6px 12px rgb(0 0 0 / 25%);
  z-index: 99;
  border-top-right-radius: 0.375rem;
  border-bottom-right-radius: 0.375rem;
}
.tmtheme_fbar_icons {
  display: block;
  position: relative;
  width: 51px;
  height: 41px;
  line-height: 40px;
  text-align: center;
  color: #000;
}
.tmtheme_fbar_icons > span {
  display: block;
  position: absolute;
  left: 100%;
  top: 0;
  background-color: #fff;
  box-shadow: 0 6px 12px rgb(0 0 0 / 25%);
  opacity: 0;
  visibility: hidden;
  font-size: 16px;
  color: #232323;
  transform: translateX(-5px);
  transition: 0.5s ease;
  padding: 0 15px;
}
.tmtheme_fbar_icons:hover > span {
  opacity: 1;
  visibility: visible;
  transform: translateX(0);
}

.scroll-to-top {
  background: #c6252a !important;
  line-height: 0;
}
.scroll-to-top svg {
  fill: #fff !important;
  width: 17px;
  height: 17px;
}

.main-slider .owl-carousel .owl-nav button.owl-prev, .owl-carousel .owl-nav button.owl-next, .owl-carousel button.owl-dot{
  border-radius: 50%;
  box-shadow: none;
}

.main-slider .owl-carousel .owl-nav button.owl-prev {
  position: absolute;
  width: 50px;
  height: 50px;
  left: 20px;
  font-size: 45px;
  text-align: center;
  color: #c6252a;
  line-height: 0;
  border: 1px solid #c6252a;
  transition: all 500ms ease;
  -moz-transition: all 500ms ease;
  -webkit-transition: all 500ms ease;
  -ms-transition: all 500ms ease;
  -o-transition: all 500ms ease;
}
.main-slider .owl-carousel .owl-nav button.owl-next {
  position: absolute;
  right: 20px;
  width: 50px;
  height: 50px;
  font-size: 45px;
  text-align: center;
  color: #c6252a;
  line-height: 0;
  border: 1px solid #c6252a;
  transition: all 500ms ease;
  -moz-transition: all 500ms ease;
  -webkit-transition: all 500ms ease;
  -ms-transition: all 500ms ease;
  -o-transition: all 500ms ease;
}
.main-slider .owl-carousel .owl-nav {
  position: absolute;
  left: 0px;
  top: 40%;
  z-index: 1;
  width: 100%;
}
.main-slider .owl-theme .owl-nav [class*='owl-']:hover {
  background: #c6252a;
  color: #FFF;
 
}
.main-slider .owl-nav span{
  height: 14px;
    display: block;
}

.about_thumb img {
  border-radius: 7px;
}
.director-massage img {
  border-radius: 5px;
  border: 5px double #000;
}
.teacher-details-content h2{
  font-size: 28px;
}
.director-massage-page .col-lg-8{
margin: auto;
}
.about-us-img img{
  border-radius: 7px;
}
.about-bottom-content p{
  margin-bottom: 5px;
  text-align: center;
}
.about-bottom-content{
  margin-top: 15px;
}
.Nav-links-mobile{
  width: 100%;
}